export const productType = {
    MSP: {
      product: "MSP",
      name: "MSP",
      buttonName: "Managed Service Provider",
      hubspotValue: "MSP",
      description: "How many technicians are on your team?",
      errorMessage: "please select the technician count.",
      category: ["1-2", "3-5", "6-10", "11-15", "16-25", "26-50", "50+"],
      tooltipMessage:
        "If you provide IT services to other companies or organizations as an external service provider.",
    },
    IT: {
      product: "IT",
      name: "IT",
      buttonName: "Part of an IT Team",
      hubspotValue: "Internal IT",
      description: "How many employees do you have?",
      errorMessage: "please select the device count.",
      category: ["1-50", "51-100", "101-250", "251-500", "501-1000", "1001+"],
      tooltipMessage:
        "If you work as part of an in-house IT team managing technology and systems for your own organization.",
    },
  }